import { useUtils as useAclUtils } from "@core/libs/acl";
import { getUserRoles } from "@/auth/utils";

const { canViewVerticalNavMenuHeader } = useAclUtils();

export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    render(h) {
        const span = h("span", {}, this.item.header);
        const icon = h("feather-icon", {
            props: { icon: "MoreHorizontalIcon", size: "18" }
        });
        // if (canViewVerticalNavMenuHeader(this.item) && this.item.roles.indexOf(getUserData().role) != -1) {
        if (
            canViewVerticalNavMenuHeader(this.item) &&
            this.item.roles.indexOf(getUserRoles()) != -1
        ) {
            return h("li", { class: "navigation-header text-truncate" }, [
                span,
                icon
            ]);
        }
        return h();
    }
};
